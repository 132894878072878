<template lang="pug">
.home.view
  book-slideshow(:records="slideshowBooks", v-if="hasRecords", :slidesToShow="3", ref="slideshow")
  auto-scroller(@beat="scroll" :active="autoscroll")
</template>

<script>
import BookSlideshow from "/src/components/BookSlideshow.vue";
import AutoScroller from "../components/AutoScroller.vue";

// comment faire pour donner un peu de vie à cette application?
  // comment est-ce que le autoscroller décide de se gérer en fct du userStatus?
  // comment afficher le contrôle de l'utilisateur absent?

export default {
  name: "ViewHome",
  components: { AutoScroller, BookSlideshow },

  props: {autoscroll: Boolean},

  computed: {
    hasRecords() {
      return !!this.slideshowBooks?.length;
    },

    slideshowBooks() {
      return this.$router.app.slideshowBooks;
    },

    userAbsent(){
      return this.$router.app.userStatus == "absent";
    }
  },

  methods: {
    scroll(){
      return this.$refs.slideshow?.scrollNext();
    }
  }
};
</script>

<style lang="sass" scoped>
.home.view
  margin-top: 5vh
</style>
