<template lang="pug">
span.text-clock {{ text }}
</template>

<script>
export default {
  data() {
    return { text: "--:--" };
  },

  mounted() {
    setInterval(() => {
      this.text = new Date().toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });
    }, 1000);
  },
};
</script>