import VueRouter from "vue-router";

import Home from "/src/views/Home.vue";
import OpBooksAll from "/src/views/OpBooksAll.vue";
import ViewBookShow from "/src/views/ViewBookShow.vue";
import ViewCategoryItem from "/src/views/ViewCategoryItem.vue";
import ViewCategories from "/src/views/ViewCategories.vue";

function idParam(route) {
  return { id: route.params.id };
}

let router = new VueRouter({
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },

  routes: [
    {
      path: "/",
      component: Home
    },
    {
      name: "BookShow",
      path: "/books/:id",
      component: ViewBookShow,
      props: idParam
    },
    {
      path: "/op/books",
      component: OpBooksAll
    },
    {
      name: "Categories",
      path: "/sujets",
      component: ViewCategories
    },
    {
      name: "topic",
      path: "/sujets/:id",
      component: ViewCategoryItem,
      props: idParam
    }
  ]
});

export default router;
