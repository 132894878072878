<template lang="pug">
.operator.books.all.view
  h1 Livres

  .books.list
    book-list-item(
      v-for="(record, i) in store.records",
      v-bind="record",
      :id="i",
      :key="i"
    )
</template>

<script>
export default {};
</script>

<style lang="sass">
.operator.books.all.view
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  padding: 1em 2em
  background: #ffffffea
  color: black
  overflow-y: scroll
</style>