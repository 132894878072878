<template lang="pug">
#app(@contextmenu="onRightClick" @pointerdown="refreshSession()")
  loading-screen(v-show="showLoadingScreen" @finish="splashDelayFinished = true")
    data-source(key="books" :books.sync="books" :url="booksUrl" v-if="booksUrl")
    data-source(key="topics" @update:classifications="ingestTopics($event)" :url="topicsUrl" v-if="topicsUrl")

  slot

  app-screen(v-if="!showLoadingScreen")
    template(v-slot:branding="")
      h1 {{title}}

    router-view#view.scroll(v-if="loaded" :autoscroll="autoscrollActive")
    session-tracker(ref="sessionTracker" v-bind:status.sync="userStatus" :delay="120" @expire="onExpire")
</template>

<script>
import router from "./lib/router.js";

import DataSource from "./components/DataSource.vue";
import LoadingScreen from "./components/LoadingScreen.vue";
import SessionTracker from "./components/SessionTracker.vue";

import AppScreen from "./screens/AppScreen.vue";
import ScreenBanq from "./screens/ScreenBanq.vue";
import ScreenBsi from "./screens/ScreenBsi.vue";

import {keyBy, sampleSize} from "lodash";


export default {
  name: "App",
  router,

  components: {
    AppScreen,
    DataSource,
    LoadingScreen,
    ScreenBanq,
    ScreenBsi,
    SessionTracker,
  },

  props: {
    title: String,
    booksUrl: {type: String, required: true},
    topicsUrl: {type: String, required: true},
  },

  data() {
    return {
      books: [],
      topics: [],

      userStatus: null,

      randomSeed: Math.random(),
      forceLoadingScreen: false,
      splashDelayFinished: false,
    };
  },

  computed: {
    loaded(){
      return !!(this.books.length && this.topics.length);
    },

    showLoadingScreen(){
      return this.forceLoadingScreen || !this.loaded || !this.splashDelayFinished;
    },

    topicsById(){
      return keyBy(this.topics, "identifier");
    },

    booksById(){
      return keyBy(this.books, "identifier");
    },

    autoscrollActive(){
      return (this.userStatus == "absent")
    },

    slideshowBooks() {
      let coll = this.books;
      coll = coll.filter((r) => r.cover)

      if (!coll.length) return [];

      let n = 30;

      let m = coll.length - n;
      let i = Math.floor(this.randomSeed * m);
      return coll.slice(i, i + 30);
    },

    displayedTopics(){
      if(!this.topics.length) return;
      return sampleSize(this.topics, 100)
    }
  },

  methods: {
    ingestTopics(topics){
      topics = topics
        .map(cls => ({...cls, label: cls.captions?.fr, bookIds: cls.bookIdentifiers}))
        .filter(cls => cls.label)

      this.topics = topics
    },

    onRightClick(e){
      if(e.buttons == 3){ return; }
      if(e.shiftKey){ return; }
      e.preventDefault();
    },

    refreshSession(){
      let tracker = this.$refs.sessionTracker;
      return tracker?.touch();
    },

    onExpire(){
      this.slideshowBooks = this.randomSlideshowBooks();
      this.shuffleTopics();
      this.goHome();
    },

    goHome(){
      // Will reject if already at '/'
      this.$router.push("/").catch(e=>e)
    },

    // shuffleTopics(){
    //   this.displayedTopics = sampleSize(this.topics, 100)
    // },

    onLoad(){
      this.loaded = true;
      // this.shuffleTopics();
    },
  }
};
</script>

<style lang="scss">
* { box-sizing: border-box; }

html, body { margin: 0; }
#app { display: flex; flex-direction: column; height: 100vh; }

//-  Font
body { font-family: Work Sans, sans-serif; }

.flex {
  display: flex;
}

.float-left {
  float: left;
  margin-right: 1em;
}

table {
  td {
    padding: 0.1em 0.5em 0.1em 0em;
  }
}

.button {
  font-size: 1.1em;
  padding: 0.4em 1.2em;
  border-radius: 3px;
  border: 0;
  cursor: pointer;

  background: hsl(210, 20%, 50%);
  color: white;
}

nav.back { font-size: 0.9em; margin-bottom: 2.0rem; }

a.back {
  padding: 0.4em 0.8em; border-radius: 0.3em;
  border: 1px solid #ffffff66;
}


.section {
  margin: 1em 0;
}

.container { max-width: 40rem; margin: auto; }

// Especially on Windows
.scroll { scrollbar-width: none; overflow-y: auto;}
.scroll::-webkit-scrollbar { display: none; }
</style>
