<template lang="pug">
app-screen.screen-bsi.theme-bsi
  template(v-slot:branding="")
    strong Bibliothèque des sciences et de l'industrie

  slot
</template>

<script>
import AppScreen from "./AppScreen.vue";
import fetchJson from "../lib/fetchJson.js";


export default {
  components: {
    AppScreen,
  },

  data(){
    return {
      urls: {
        books: "https://manivelle-cdn-1.nyc3.cdn.digitaloceanspaces.com/bsi/bsi-numilog-2021-10-07-books.json",
        topics: "https://manivelle-cdn-1.nyc3.cdn.digitaloceanspaces.com/bsi/bsi-numilog-2021-10-07-topics.json",
      }
    }
  },

  mounted(){
    this.loadData()
  },

  methods: {
    async loadBooks(url){
      let {books} = await fetchJson(url)

      books = books.map(b => this.loadBook(b)).filter(b => b.cover)

      this.$emit("update:books", books);
    },

    loadBook(original){
      let { identifier, creators, ...metadata } = original;

      let {
        description: summary,
        relation: externalLink,
        date: publicationDate,
      } = metadata;

      return ({ identifier, original,
        authorNames: creators,
        summary,
        externalLink,
        publicationDate,
        ...metadata });
    },

    async loadTopics(url){
      let {topics} = await fetchJson(url, {camelize: true})

      this.$emit("update:topics", topics)
    },

    async loadData(){
      let {urls} = this
      let books = this.loadBooks(urls.books)
      let topics = this.loadTopics(urls.topics);

      await books;
      await topics;

      this.$emit("loaded")
    }
  }
};
</script>

<style lang="sass">
</style>
