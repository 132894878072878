<template lang="pug">
.book-record
  .flex.columns
    .left
      book-cover(:image="cover")

      section.link(v-if="externalLink")
        h3.discrete Lien d'accès
        vue-qrcode.qrcode(:value="externalLink", v-bind="qrcodeParams")
        //- p.url: code {{ externalLink }}

    .right
      .title-card
        h1.title {{ title }}

        .authors.comma-separated
          template(v-for="(item,i) in authorNames")
            span.sep(v-if="i != 0")= ", "
            a.item(:title="item") {{item}}

      section.metadata
        table.metadata
          tr(v-if="publisher")
            td Éditeur
            td {{ publisher }}

          tr(v-if="publicationDate")
            td Parution
            td {{ formattedPublicationDate }}

          tr(v-if="isbn")
            td ISBN
            td {{ formattedIsbn }}

      section.topics
        //- p {{ categoriesEnum }}

      section.summary
        p(v-if="summary") {{ summary }}
        p.hint(v-else)
          em (description non-disponible)
</template>

<script>
import VueQrcode from "vue-qrcode";
import BookCover from "/src/components/BookCover.vue";

export default {
  props: {
    title: String,
    authorNames: Array,
    details: { type: Object, default: () => ({}) },
    summary: String,
    cover: String,
    externalLink: String,
    topics: Array,
    isbn: String,
    publicationDate: String,
    publisher: String,
  },

  components: { BookCover, VueQrcode },

  computed: {
    formattedPublicationDate(){
      let {publicationDate} = this;
      if(!publicationDate){ return }

      publicationDate = new Date(publicationDate)

      return publicationDate?.toLocaleDateString(
        "fr",
        {month: 'long', year: "numeric"}
      );
    },

    formattedIsbn(){
      let {isbn} = this;
      if(!isbn){ return }
      if(isbn.match(/-/)){ return isbn }

      let prefix = isbn.slice(0,3)
      let lang = isbn.slice(3,4)
      let large = isbn.slice(4,7)
      let last = isbn.slice(7,13)

      return [prefix, lang, large, last].join("-")
    },

    qrcodeParams() {
      const BASE_WIDTH = 80;
      const { devicePixelRatio } = window;

      let width = BASE_WIDTH * devicePixelRatio * 2;
      let margin = 1 * devicePixelRatio;

      return {
        width,
        margin,
        errorCorrectionLevel: "M",
      };
    },
  },
};
</script>

<style lang="sass" scoped>
.title
  line-height: 1.3

.description
  font-size: 0.9em

  .hint
    font-size: 0.8em
    opacity: 0.5

table.metadata
  margin: 0.6em 0
  margin-left: -0.2em
  font-size: 0.9em

  td:first-child
    color: rgba(255, 255, 255, 0.5)
    width: 6em

  td
    padding-top: 0.2em

.summary
  font-size: 0.9em
  max-width: 32em
  line-height: 1.7
  font-weight: 300
  text-align: justify
  margin-top: 2em

.columns
  gap: 3em

.left
  flex: 0 1 auto
.right
  flex-grow: 1
  max-width: 36em

.book-cover
  font-size: 3.2em

.qrcode
  width: 5em
  min-width: 80px
  border-radius: 0.3em

.url
  font-size: 0.8em
  color: rgba(white, 0.7)

h3.discrete
  font-weight: 500
  font-size: 1em

section.link
  margin-top: 2em
</style>
