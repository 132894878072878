<template lang="pug">
.categories.view
  .topics.flex.numbered-sizes
    template
      template(v-for="topic in topics")

        router-link.topic(
          :to="{name: 'topic', params: {id: topic.identifier}}",
          :data-size="topic.size"
          :style="({...styles[topic.i % 16], fontSize: topic.fontSize})"
        )
          .text {{ topic.label }}
      //- span.break

    //- .topic(v-for="i in [1, 2, 3, 4]") {{ i }}
    //- span.break
    //- .topic(v-for="i in [5, 6, 7, 8]") {{ i }}
    //- span.break
    //- .topic(v-for="i in [9, 10, 11, 12]") {{ i }}
    //- span.break
    //- .topic(v-for="i in [13, 14, 15, 16]") {{ i }}

  .actions
    //- center: button.button(@click="shuffle") Shuffle!
</template>

<script>
// ( ) quels sujets sont sélectionnés?
//     ( ) shuffle
// ( ) cliquer sur une bulle

// [?] comment les sujets sont disposés
// sur l'écran de façon originale

const numberToFontSize = function(n){
  if(n > 50){ n = 50 }

  // let s = Math.sqrt(n)
  let b = Math.log(n)
  return b * 0.15 + 0.7
}

import ColorScheme from "color-scheme";

export default {
  name: "ViewCategories",

  components: {},

  props: {autoscroll: Boolean},

  computed: {
    colors() {
      let hue = 16
      let scheme = new ColorScheme();

      scheme.from_hue(hue).scheme("tetrade");

      // console.log(scheme.colors());
      return scheme.colors();
    },

    styles() {
      let colors = this.colors;

      let styles = colors.map(
        (color) => ({background: `#${color}`})
      );

      // console.log(styles);
      return styles;
    },

    topics() {
      let topics = this.$router.app.displayedTopics;
      if(topics.length == 0) return;

      topics = topics
        .map((topic, i) => ({
          ...topic,
          size: topic.bookIds.length,
          fontSize: `${numberToFontSize(topic.bookIds.length)}em`,
          i,
        }));

      return topics;
    },
  },

  methods: {
    shuffle() {},

    scrollMore(skipLength = 10){
      let nTopics = this.topics.length

      let {scrollTop, scrollTopMax} = this.$el
      let current = Math.floor(scrollTop/scrollTopMax * nTopics)

      let next = current + skipLength
      if(next > nTopics){ next = 0 }

      this.scrollTo(next)
    },

    scrollTo(i){
      let topic = document.querySelectorAll(".topic")[i]
      return topic.scrollIntoView({block: "center"})
    }
  },
};
</script>

<style lang="sass" scoped>
.categories.view
  scroll-behavior: smooth

.topics.flex
  display: flex
  flex-wrap: wrap
  align-items: center
  justify-content: space-evenly

.topics.grid
  display: grid
  justify-items: center
  align-items: center
  justify-content: center
  grid-template-columns: repeat(3, 1fr)
  row-gap: 2em

.break
  flex-basis: 100%
  width: 0

.square
  padding-bottom: 100%
.topic
  position: relative

.topics
  font-size: 1.0em /* flex basis?*/
  overflow: hidden

.actions
  margin-top: 4em

.topic
  font-size: 0.8em

.topics.numbered-sizes
  .topic
    width: 10em
    height: 10em

.topic
  background: #333
  color: #000000aa
  border: 1px solid #ffffff33
  border-radius: 50%

  // width: 20vw
  // height: 20vw
  margin: 0.6em 0.6em
  padding: 0.4em

  display: inline-flex
  align-items: center
  justify-content: center
  text-align: center
  font-weight: 500

  .text
    max-width: 8em
    max-height: 8em
    overflow: hidden
</style>
