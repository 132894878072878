<template lang="pug">
.tab
  router-link(:to="path")
    .arrow(style="text-align:center; line-height: 1") ⌃
    img(:src="image")
</template>

<script>
export default {
  props: {
    path: String,
    image: String,
  }
}
</script>

<style lang="sass" scoped>
.arrow
  visibility: hidden

.tab
  display: block

a
  opacity: 0.5
  display: inline-block

a:not([href="#/"]).router-link-active, a.router-link-exact-active
  opacity: 1

  .arrow
    visibility: visible

</style>