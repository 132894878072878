export default {
  state: {
    slideshowScroll: null,
  },

  mutations: {
    setSlideshowScroll(state, val) {
      state.slideshowScroll = val;
    }
  }
};
