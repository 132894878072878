<template lang="pug">
app-screen.screen-banq.theme-banq
  template(v-slot:branding="")
    strong BAnQ · Grande bibliothèque
    | Rez-de-chaussée

  slot
</template>

<script>
import AppScreen from "./AppScreen.vue";
import fetchJson from "../lib/fetchJson.js";

export default {
  components: {
    AppScreen,
  },


  mounted(){
    this.loadData();
  },

  methods: {
    async loadBooks(url){
      let {books} = await fetchJson(url)
      this.$emit("update:books", books);
    },

    async loadTopics(url){
      let {classifications} = await fetchJson(url)

      classifications = classifications
        .map(cls => ({...cls, label: cls.captions?.fr, bookIds: cls.bookIdentifiers}))
        .filter(cls => cls.label)

      this.$emit("update:topics", classifications)
    },

    async loadData(){
      let books = this.loadBooks("https://manivelle-cdn-1.nyc3.cdn.digitaloceanspaces.com/banq/banq-2021-09-08-books.json");
      let topics = this.loadTopics("https://manivelle-cdn-1.nyc3.digitaloceanspaces.com/banq/banq-2021-09-08-classifications.json");

      await books;
      await topics;

      this.$emit("loaded")
    }
  }
};
</script>

<style lang="sass">
</style>
