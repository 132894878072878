import Vue from "vue";
import VueRouter from "vue-router";
import Vuex from "vuex";

import store from "./store.js";
import App from "./App.vue";
import _ from "lodash";

import BookCover from "/src/components/BookCover.vue";
import BookListItem from "/src/components/BookListItem.vue";

Vue.use(Vuex);
Vue.use(VueRouter);

window._ = _;

Object.assign(Vue.prototype, {
  bookPath(i) {
    return `/books/${i}`;
  }
});

Vue.component("book-cover", BookCover);
Vue.component("book-list-item", BookListItem);

// createApp(App).mount("#app");
Vue.config.productionTip = false;

Object.defineProperty(Vue.prototype, "_store", {
  get() {
    return this.$root.store;
  }
});

let el = document.getElementById("app")

new Vue({
  store: new Vuex.Store(store),

  // <https://vuejs.org/v2/guide/render-function.html#The-Data-Object-In-Depth>
  render: (h) => h(App, {
    props: {...el.dataset}
  }),
}).$mount("#app");
