<template lang="pug">
.book.list-item
  section.cover
    router-link.cover-link(:to="bookPath(identifier)")
      book-cover(:image="cover")

  section.title-card
    router-link.title.link(:to="bookPath(identifier)")
      | {{ title }}

    .authors.comma-separated
      template(v-for="(item,i) in authorNames.slice(0,3)")
        span.sep(v-if="i != 0")= ", "
        a.item(:title="item") {{item}}
</template>

<script>
export default {
  props: {
    identifier: String,
    cover: String,
    title: String,
    authorNames: Array,
  },
};
</script>

<style lang="sass">
.book.list-item
  display: flex
  gap: 1.6em
  margin: 1em 0
</style>
