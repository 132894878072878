<template lang="pug">
.data-source
  .fetching(v-if="fetching")
    | En chargement...
  slot
</template>

<script>
import {map} from "lodash";
import {camelizeKeys} from "humps";

export default {
  props: {
    url: String,
  },

  model: {
    prop: "result",
    event: "change",
  },

  data() {
    return {
      result: {},
      fetching: false,
      loaded: false,
    };
  },

  mounted() {
    this.load();
  },

  methods: {
    async load() {
      let result = await this.fetchJson(this.url);
      result = camelizeKeys(result)

      this.result = result;
      this.loaded = true;

      map(result, (value, key) => this.$emit(`update:${key}`, value));
      return result;
    },

    async fetchJson(url) {
      this.fetching = true;

      let response = await fetch(url, {
        cache: "force-cache",
      });

      if(!response.ok){
        console.log(response)
        throw `DataSource: Fetching <${url}> failed`;
      }

      let result = await response.json();

      this.fetching = false;

      return result;
    },
  },
};
</script>
