<template lang="pug">
.session-tracker
  //- button(@click="active = !active")
  //- span
  //-   span(v-if="active") ⏸
  //-   span(v-else) ▶️
</template>

<script>
// const now = () => (new Date).getTime();

export default {
  props: {
    active: {type: Boolean},
    delay: {type: Number, default: () => 5000}
  },

  data(){
    return {
      intervalID: null,
    }
  },

  mounted(){
    this.start();
  },

  beforeDestroy(){
    clearInterval(this.intervalID);
  },

  methods: {
    start(){
      let intervalID = setInterval(this.act, this.delay)
      this.intervalID = intervalID
    },

    act(){
      if(!this.active){ return }
      this.$emit("beat");
    }
  }
}
</script>
