<template lang="pug">
.loading-screen

  .screen-name(v-if="screenName")
    strong {{screenName}}

  hr

  .logo
    img(src="/logo1.svg")

  div: slot
</template>

<script>
export default {
  props: {
    delay: {type: Number, default: () => 2000},
  },

  computed: {
    screenName(){ return this.$parent.title }
  },

  mounted(){
    window.setTimeout(() => this.$emit("finish"), this.delay)
  }
}
</script>

<style lang="scss" scoped>
.loading-screen {
  background: #131316;
  border-top: 3vh solid #545463;
  border-bottom: 3vh solid #1D1D3E;

  color: #ffffff99;
  font-size: 1.2em;
}

.loading-screen {
  display: flex; flex-direction: column; flex: 1 1 auto;
  justify-content: center;
  text-align: center;
}

.loading-screen > * { margin: 2em 0; }

strong {
  font-weight: 500;
  color: #fffd;
}

.loading-screen hr {
  width: 30em; margin: 2.5em auto;
  border: 0; border-top: 1px solid #fff6;
}

.logo img { width: 20rem; }

.screen-name {
  font-size: 1.25em;
  margin: 1em auto;
}
</style>
