<template lang="pug">
.book-cover: .frame.aspect
  img(:src="image")
</template>

<script>
export default {
  props: {
    image: String,
  },
};
</script>

<style lang="sass" scoped>
.book-cover
  --book-cover-color: #111
  --book-cover-shadow: #292929

.book-cover
  width: 5em

.book-cover
  background: var(--book-cover-color)
  padding: 0.1px
  border-radius: 4%
  box-shadow: 0.1em 0.12em 0px var(--book-cover-shadow)
  display: inline-block

  .frame
    margin: 8%
    min-height: 5em
    overflow: hidden
    background: #311c1c

  img
    height: auto
    width: 100%
    display: block

=fill
  width: 100%
  height: 100%
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0

.aspect
  padding-top: 120%
  position: relative

  & > *
    +fill
</style>