import {camelizeKeys} from "humps";

const fetchJson = async function(url, opts = {}) {
  let response = await fetch(url, {
    cache: "force-cache",
  });

  let result = await response.json();
  if(opts.camelize !== false){ result = camelizeKeys(result) }
  return result;
}

export default fetchJson;
